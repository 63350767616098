.employee-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  background-color: var(--bfc-base-c-wcag);
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.employee-picture img {
  width: 100%;
}

.employee-picture > .svg-inline--fa {
  color: var(--bfc-base-1);
  width: 50%;
  height: 50%;
}
