p,
i,
button,
h1,
input,
textarea {
  font-size: 14px;
}

/* Spinner container */
.before-load-spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  display: flex;
  height: calc(100vh - var(--bf-nav-top-height));
}

.seating-container {
  position: relative;
}

/* .seating-container .scrollbar-container {
  width: calc(100vw - 320px);
  max-width: 100%;
  height: calc(100vh - var(--bf-nav-top-height) - 50px - 40px);
  padding: 16px 0px 10px 0px;
} */

.grid-layout-container {
  width: calc(100vw - 280px);
  max-width: 100%;
  height: calc(100vh - var(--bf-nav-top-height) - 57px);
  overflow: auto;
  /*firefox scrollbar-styling*/
  /* scrollbar-width: thin; */
  scrollbar-color: var(--bfc-base-dimmed) var(--bfc-base-3);
}

.grid-layout-container.bf-scrollbar::-webkit-scrollbar,
.grid-layout-container.bf-scrollbar::-webkit-scrollbar-corner {
  scrollbar-color: var(--bfc-base-dimmed) var(--bfc-base-3);
  /* background-color: var(--bfc-base-3); */
}

@media (max-width: 1599px) {
  .view-mode .grid-layout-container {
    width: 100vw;
  }
}

.grid-layout {
  margin: 16px;
  margin-bottom: 120px;
  width: 1600px;
  height: 885px;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.bf-darkmode .grid-layout {
  background-image: url("../../assets/default_empty_floor_dm.svg");
}

.bf-lightmode .grid-layout {
  background-image: url("../../assets/default_empty_floor_lm.svg");
}

#dragging {
  cursor: grabbing;
}

.hide {
  display: none;
}

/* scrollbar */
/* :root .scrollbar-container .ps__rail-y,
:root .scrollbar-container .ps__rail-x {
  display: block;
  opacity: 0.6;
} */

.error-message-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Message */
.custom-message {
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 99;
  max-width: 500px;
}
