.react-grid-item.react-grid-placeholder {
  background: var(--bfc-base-1);
  border: 1px solid var(--bfc-base-c-wcag);
  border-radius: 3px;
  opacity: 0.4;
}

.desk-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
}

.desk-container.disabled .desk .desk-employee {
  opacity: 0.6;
}
.desk-container.disabled .desk {
  border-color: var(--bfc-base-dimmed);
}
.desk-container.disabled .chair {
  background: var(--bfc-base-dimmed);
}

.desk-container.rotated-90 {
  flex-direction: column;
}

.desk-container.rotated-180 {
  flex-direction: row-reverse;
}

.desk-container.rotated-270 {
  flex-direction: column-reverse;
}

.desk {
  background-color: var(--bfc-base-3);
  flex: 1;
  height: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 3px;
  cursor: grab;
  border: 1px solid var(--bfc-base-c-wcag);
}

.desk.editor {
  cursor: pointer;
}

.desk.selected,
.desk-container:hover .desk {
  border: 1px solid var(--bfc-base-c-theme);
  background-color: var(--bfc-theme-c-1);
}

.react-draggable-dragging .desk,
.react-draggable-dragging .desk-employee {
  cursor: grabbing;
}

.desk.hover {
  border: 1px solid var(--bfc-base-c-theme);
}

.desk.hover * {
  pointer-events: none;
}

.view-mode .desk-container:hover .desk.empty {
  cursor: default;
  border: 1px solid var(--bfc-base-c-wcag);
  background-color: var(--bfc-base-3);
}

.view-mode .desk.selected,
.view-mode .desk-container:hover .desk {
  cursor: pointer;
}

.desk-container.rotated-90 .desk,
.desk-container.rotated-270 .desk {
  width: 100%;
}

.desk-container.rotated-90 .desk {
  margin: 0;
  margin-bottom: 3px;
}

.desk-container.rotated-180 .desk {
  margin: 0;
  margin-left: 3px;
}

.desk-container.rotated-270 .desk {
  margin: 0;
  margin-top: 3px;
}

.chair {
  background-color: var(--bfc-base-c-wcag);
  border-radius: 5px;
  width: 5px;
  height: 40%;
}

.view-mode .desk-container:hover .desk.empty ~ .chair {
  background-color: var(--bfc-base-c-wcag);
}

.desk.selected ~ .chair,
.desk-container:hover .desk ~ .chair {
  background-color: var(--bfc-theme-1);
}

.desk-container.rotated-90 .chair,
.desk-container.rotated-270 .chair {
  width: 40%;
  height: 5px;
}

.dummy-employee-input {
  min-width: 350px;
}

/*desk tools*/
.desk-tools-container {
  display: flex;
  padding: 4px 2px;
  background: var(--bfc-base-1);
  border-radius: 3px;
  z-index: 100;
}
.desk-tools-container .desk-tool-btn {
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px;
  color: var(--bfc-theme-1);
  border-radius: 50%;
  padding: 4px;
  outline: none;
  border: 1px solid transparent;
  width: 24px;
  height: 24px;
}
.desk-tool-btn:hover {
  background: var(--bfc-theme-c-2);
}
.desk-tool-btn.focus-visible {
  border: 1px dashed var(--bfc-theme-1);
}
