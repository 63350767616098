:root {
  --yellow: #fff500;
  --yellow-50: #fff50080;
  --red: #c50000;
  --red-50: #c5000080;
  --light-purple: #c8c7ff;
  --light-purple-50: #c8c7ff80;
  --purple: #8000ff;
  --purple-50: #8000ff80;
  --cyan: #00e0ff;
  --cyan-50: #00e0ff80;
  --dark-cyan: #056d78;
  --dark-cyan-50: #056d7880;
  --gray: #a3a3a3;
  --gray-50: #a3a3a380;
  --dark-green: #006a1e;
  --dark-green-50: #006a1e80;
  --white: #fff;
  --white-50: #ffffff80;
  --light-pink: #ffa0a0;
  --light-pink-50: #ffa0a080;
  --blue: #0038ff;
  --blue-50: #0038ff80;
  --pink: #ff007a;
  --pink-50: #ff007a80;
  --black: #000;
  --black-50: #00000080;
  --light-green: #00ff47;
  --light-green-50: #00ff4780;
}

.yellow {
  background-color: var(--yellow);
}

.red {
  background-color: var(--red);
}

.light-purple {
  background-color: var(--light-purple);
}

.purple {
  background-color: var(--purple);
}

.cyan {
  background-color: var(--cyan);
}

.dark-cyan {
  background-color: var(--dark-cyan);
}

.gray {
  background-color: var(--gray);
}

.dark-green {
  background-color: var(--dark-green);
}

.white {
  background-color: var(--white);
}

.light-pink {
  background-color: var(--light-pink);
}

.blue {
  background-color: var(--blue);
}

.pink {
  background-color: var(--pink);
}

.black {
  background-color: var(--black);
}

.light-green {
  background-color: var(--light-green);
}

.red p,
.purple p,
.dark-cyan p,
.dark-green p,
.blue p,
.black p {
  color: #fff;
}

.yellow p,
.light-purple p,
.cyan p,
.gray p,
.white p,
.light-pink p,
.pink p,
.light-green p {
  color: #000000;
}
