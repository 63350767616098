.list-employee,
.desk-employee {
  user-select: none;
  cursor: grab;
}

.list-employee p,
.desk-employee p {
  line-height: 14px;
}

/* List employee */
.list-employee {
  display: flex;
  align-items: center;
  padding-left: 42px;
  padding-right: 35px;
}
.list-employee .employee-picture {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.list-employee:hover > p {
  text-decoration: underline;
}
.list-employee.placed {
  color: var(--bfc-base-c-2);
  cursor: pointer;
  position: relative;
}
.list-employee.placed:hover {
  color: var(--bfc-base-c-1);
}
.list-employee.placed .employee-picture {
  opacity: 0.4;
}
.list-employee.placed:hover .employee-picture {
  opacity: 1;
}
.list-employee.placed .go-to-employee-icon {
  position: absolute;
  right: 16px;
  color: var(--bfc-theme-1);
}

/* Desk employee */

.desk-employee {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 100%;
}

.desk-container.rotated-90 .desk-employee,
.desk-container.rotated-270 .desk-employee {
  margin-top: 2px;
}

.view-mode .desk-employee {
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin-top: 0;
  padding-top: 20px;
}

.view-mode .desk-container.rotated-90 .desk-employee,
.view-mode .desk-container.rotated-270 .desk-employee {
  padding-top: 2px;
  margin-top: 0;
}

.desk-employee .employee-name {
  margin: 0;
  max-width: 100%;
  word-break: break-word;
  line-height: 15px;
  font-size: 12px;
  max-height: calc(15px * 2);
  overflow: hidden;
}

.desk-container.rotated-90 .employee-name,
.desk-container.rotated-270 .employee-name {
  max-height: 15px;
}

.employee-avatar-container {
  position: relative;
  margin-bottom: 8px;
}

.employee-img-container {
  width: 33px;
  height: 33px;
}

.desk-container.rotated-90 .employee-img-container,
.desk-container.rotated-270 .employee-img-container {
  width: 26px;
  height: 26px;
}

.employee-department-badge {
  width: 18px;
  height: 18px;
  background-color: #000;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5px;
  right: -3px;
}

.employee-department-badge p {
  font-size: 9px;
  font-weight: bold;
}

.comment-icon {
  position: absolute;
  cursor: pointer;
  top: 3px;
  right: 3px;
}

.react-draggable-dragging .comment-icon {
  cursor: grabbing;
}

/* DESK ICONS */
.desk-icon {
  color: var(--bfc-base-c-2);
}
.desk-container:hover .desk-icon,
.selected .desk-icon {
  color: var(--bfc-theme-3);
}
.edit-mode .desk-container:hover .desk-icon:hover {
  color: var(--bfc-theme-1);
}

/* Dummy Employee */

.dummy-employee {
  margin: 0;
  word-break: break-word;
  hyphens: auto;
  text-align: center;
  padding: 0 3px;
  cursor: grab;
}

.view-mode .dummy-employee {
  cursor: default;
}

.dummy-employee-edit-icon {
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  right: 3px;
}
