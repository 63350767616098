:root {
  --app-name-height: 30px;
  --sidebar-width: 280px;
}

.bf-nav.bf-nav-mobile {
  padding-bottom: 0px;
}

.bf-nav-side-content {
  overflow-y: unset;
}

.side-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  box-sizing: border-box;
  border-right: 1px solid var(--bfc-base-dimmed);
}

.view-mode .side-menu-container {
  height: 100%;
}

@media (max-width: 959px) {
  .view-mode .side-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border-right: 1px solid var(--bfc-base-dimmed);
  }
}

.logo-container {
  height: var(--app-name-height);
  padding: 0px 20px;
  border-bottom: 1px solid var(--bfc-base-c-dimmed);
  background: var(--bfc-base-2);
}
.logo-container > p {
  margin: 0;
  line-height: var(--app-name-height);
  color: var(--bfc-base-c-2);
  font-style: italic;
}

.employee-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--bfc-base-3);
}

.employee-message-container {
  margin: 10px 15px;
  opacity: 0.4;
}

.employee-message-container p {
  margin: 0;
}

.filter-options {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: var(--bfc-base-3);
}

/* Search field */
.search-field-container {
  padding-bottom: 12px;
}
.search-field-container .bf-input {
  background-color: var(--bfc-base-2);
  font-size: 14px;
}

/* Filter view options */
.filter-view-options {
  display: flex;
  position: relative;
  min-height: 27px;
  align-items: center;
}
.filter-view-options .collapse-option {
  margin: 0;
  color: var(--bfc-base-c-1);
  cursor: pointer;
}
.filter-view-options .collapse-option:hover {
  color: var(--bfc-theme);
}
.filter-view-options .collapse-option svg {
  border: 1px solid var(--bfc-theme);
  color: var(--bfc-theme);
  padding: 2px;
  height: 15px;
  width: 15px;
  margin-left: 4px;
  margin-right: 6px;
}
.filter-view-options .collapse-option:hover svg {
  background: var(--bfc-theme);
  color: var(--bfc-base-3);
}
.filter-view-options .placed-options {
  display: flex;
  position: absolute;
  right: 0px;
}
.filter-view-options .placed-options .bf-checkbox-wrapper:first-child {
  margin-right: 6px;
}

.filter-view-options .placed-options .bf-checkbox-wrapper .bf-checkbox-label {
  padding-left: 20px;
}

/* Departments */
.employee-section .scrollbar-container {
  height: calc(
    100vh - var(--bf-nav-top-height) - var(--app-name-height) - 103px - 200px
  );
  overflow: auto;
  /*firefox scrollbar-styling*/
  scrollbar-width: thin;
  scrollbar-color: var(--bfc-base-dimmed) transparent;
}

@media (max-height: 599px) {
  .view-mode .employee-section {
    flex-grow: 2;
    overflow: hidden;
  }

  .view-mode .employee-section .scrollbar-container {
    flex-grow: 2;
    height: unset;
  }

  .view-mode .filter-options {
    flex-grow: 1;
  }
}

.departments-container {
  overflow: auto;
  background-color: var(--bfc-base-2);
}

.department-header {
  padding: 10px 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--bfc-base-3);
}

.department-header.open .department-name-container svg,
.department-header.open .department-name-container p {
  color: var(--bfc-base-c-theme);
}

.department-header:hover .department-name-container svg,
.department-header:hover .department-name-container p {
  color: var(--bfc-base-c-theme);
}

/* empty departs */
.department-container.empty {
  user-select: none;
}
.department-container.empty .department-header {
  cursor: default;
}
.department-container.empty .department-name-container svg,
.department-container.empty .department-name-container p,
.department-container.empty
  .department-header:hover
  .department-name-container
  svg,
.department-container.empty
  .department-header:hover
  .department-name-container
  p {
  color: var(--bfc-base-c-disabled);
}
.department-container.empty .department-icon {
  opacity: 0.2;
}

.department-header p {
  margin: 0;
}

.department-name-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.department-name-container span {
  opacity: 0.5;
}

.department-header .fa-angle-right {
  margin-right: 15px;
}

.department-header .fa-angle-down {
  margin-right: 12px;
}

.department-icon {
  border-radius: 50%;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  margin-left: 15px;
}

/* Drop zone */
.drop-zone-container {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bfc-base-1);
  position: absolute;
  box-sizing: border-box;
  border-right: 1px solid var(--bfc-base-c-dimmed);
  height: calc(
    100vh - var(--bf-topbar-height) - var(--app-name-height) - 200px
  );
}

.drop-zone {
  border: 1px dashed var(--bfc-base-c-theme);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px;
  line-height: 25px;
  position: absolute;
  box-sizing: border-box;
  width: 95%;
  height: 98%;
}

.drop-zone.hover {
  border: 1px solid var(--bfc-base-c-theme);
}

/* Floor menu */
.floor-menu-container {
  background-color: var(--bfc-base-2);
  border-top: 1px solid var(--bfc-base-dimmed);
  box-sizing: border-box;
  height: 200px;
  display: block;
}

.floor-menu-accordion-container {
  display: none;
  background-color: var(--bfc-base-2);
}

.floor-menu-accordion-container.open {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.floor-menu-accordion-container .bf-accordion-item {
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: var(--bfc-base-2);
}

.floor-menu-accordion-container.open .bf-accordion-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.floor-menu-accordion-container .bf-accordion-item .bf-accordion-item-title {
  background-color: var(--bfc-base-3);
}

.floor-menu-accordion-container
  .bf-accordion-item
  .bf-accordion-item-title
  .bf-accordion-item-angle-wrapper
  .bf-accordion-item-angle {
  transform: rotate(-90deg);
}

.floor-menu-accordion-container
  .bf-accordion-item-active
  .bf-accordion-item-title
  .bf-accordion-item-angle-wrapper
  .bf-accordion-item-angle {
  transform: rotate(90deg);
}

.floor-menu-accordion-container.open .bf-accordion-item > div {
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--bfc-base-dimmed) transparent;
}

.floor-menu-accordion-container .bf-accordion-item .bf-accordion-item-content {
  height: 100%;
}

@media (max-height: 599px) {
  .view-mode .floor-menu-container {
    display: none;
  }
  .view-mode .floor-menu-accordion-container {
    display: flex;
  }
}

/* Shadow fade */
.floor-menu-container:before {
  content: "";
  z-index: 2;
  width: calc(var(--sidebar-width) - 10px);
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: linear-gradient(transparent 25px, var(--bfc-base-2));
  pointer-events: none;
}

@media (max-width: 1599px) {
  .view-mode .side-menu-container .floor-menu-container:before {
    display: none;
  }
}

.locations-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 16px 20px;
}

.locations-dropdown h1 {
  margin: 0;
  margin-right: 8px;
}

.locations-dropdown h1,
.locations-dropdown svg {
  color: var(--bfc-base-c-1);
}

.locations-dropdown:hover h1 {
  text-decoration: underline;
}

.locations-content .tippy-content {
  padding: 0;
}

.locations-content .location .bf-checkbox-label {
  display: flex;
  justify-content: space-between;
}

.bf-darkmode .locations-content.bf-dropdown {
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
.bf-lightmode .locations-content.bf-dropdown {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}

.floor-menu-container .scrollbar-container {
  height: 146px;
  overflow: auto;
  /*firefox scrollbar-styling*/
  scrollbar-width: thin;
  scrollbar-color: var(--bfc-base-dimmed) transparent;
}

.floor-menu-accordion-container .scrollbar-container {
  height: unset;
  overflow: auto;
  /*firefox scrollbar-styling*/
  scrollbar-width: thin;
  scrollbar-color: var(--bfc-base-dimmed) transparent;
}

.floors-container {
  padding: 0 20px 15px;
}

.floor-container {
  display: flex;
  margin: 8px 0;
}

.floor-menu-accordion-container .floor-container {
  margin: 16px 0;
  min-width: min-content;
}

.floor-number {
  color: var(--bfc-base-c-2);
  margin: 1px 8px 0 0;
}

.floor-menu-container .bf-tag {
  margin: 0px 4px;
  flex-grow: 1;
  flex-shrink: 0;
}

.floor-menu-accordion-container .bf-tag {
  margin: 0px 4px;
  flex-grow: 1;
  flex-shrink: 0;
}
