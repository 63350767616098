.map .bf-nav.bf-nav-side ~ .bf-nav-top > .bf-nav-top-appname > span {
  display: none;
}
.map .bf-nav-top {
  z-index: 999;
  border-bottom: var(--bfl-border);
}
.edit-mode.map .bf-intility-logo {
  width: 280px;
}

.edit-mode.map .bf-nav-top {
  min-width: 900px;
}

.map .bf-nav-top-appname {
  display: none;
}
.map .bf-nav-top-appname span {
  display: none;
}

.map .bf-nav-top-content {
  flex-grow: 2;

  align-items: center;
}

.map .bf-nav-top .bf-nav-item,
.map .bf-nav-top .bf-intility-logo,
.map .bf-nav-top .bf-intility-logo[class] > a {
  border-bottom: none;
}

/* Tool menu */
.map .bf-nav-top-content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.view-mode.map .bf-nav-top-content {
  display: flex;
}

.view-mode.map .bf-nav-top-content .dashboard-btn {
  text-decoration: none;
  display: block;
  color: inherit;
  outline: none;
}

.map .bf-nav-top-content h5 {
  margin: 0;
  text-align: center;
  grid-column: 2;
}

/* When page is smaller than 1500px */
@media screen and (max-width: 1499px) {
  .map .bf-nav-top-content h5 {
    display: none;
  }
}

.menu-options-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 3;
}

/* When page is smaller than 1150px */
@media screen and (max-width: 1150px) {
  .add-desk-container > p {
    display: none;
  }
}

/* ADD DESK */
.add-desk-container {
  padding-left: 16px;
  display: flex;
  align-items: center;
}
.add-desk-icon-container {
  display: flex;
  cursor: grab;
  align-items: center;
}
.add-desk-icon {
  display: flex;
  height: 30px;
  width: 22px;
  border: 1px solid var(--bfc-theme);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  color: var(--bfc-base-c-1);
}
.add-desk-icon-container:hover .add-desk-icon,
.add-desk-icon-container:hover svg {
  background-color: var(--bfc-theme);
  color: var(--bfc-theme-c-1);
}
.add-desk-icon > .svg-inline--fa {
  width: 10px;
}
.add-desk-icon-chair {
  display: block;
  height: 15px;
  border-right: 2px solid var(--bfc-theme);
  border-radius: 3px;
  margin-left: 3px;
  margin-right: 8px;
}

.add-desk-container p,
.add-desk-container svg {
  color: var(--bfc-base-c-1);
  margin: 0;
}

/* DESK TOOLS*/

/* .desk-tools-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desk-tool-btn {
  cursor: pointer;
  font-size: 14px;
  margin: 0 16px;
  color: var(--bfc-base-c-theme);
}
.desk-tool-btn:hover {
  color: var(--bfc-theme-2);
} */

/* MENU ITEMS */
.menu-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lock-employees.bf-checkbox-wrapper {
  border: 1px solid var(--bfc-base-c-dimmed);
  border-radius: 3px;
  padding: 0px 8px;
  padding-top: 2px;
  height: 32px;
}
@media screen and (max-width: 1400px) {
  .menu-items .bf-checkbox-wrapper {
    display: none;
  }
}

/* EDIT SAVE OPTIONS */
.edit-options {
  margin-right: 16px;
}

@media (max-width: 959px) {
  .view-mode .edit-options {
    display: none;
  }
}
.edit-options .edit-options-button {
  min-width: 95px;
  margin-left: 8px;
}

.edit-options .edit-options-button.editing {
  min-width: 95px;
  padding: 0;
}
.edit-options .edit-options-button.editing svg {
  margin-right: 6px;
}

.profile-img-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0;
  margin-right: 6px;
  overflow: hidden;
}

.profile-img-container img {
  width: 100%;
}

.edit-options .bf-badge {
  padding: 4px 12px;
}

/* DASHBOARD */
@media (max-width: 959px), (max-height: 600px) {
  .view-mode .bf-nav-top-content > a.dashboard-btn {
    display: none;
  }
}

.bf-nav-top-content > a {
  height: 100%;
}

.bf-nav-top-content .bf-nav-item.editing {
  cursor: default;
  color: var(--bfc-base-disabled);
}
