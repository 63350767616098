html,
body,
#root,
.App {
  /* height: 100%; */
  width: 100%;
  overflow: hidden;
  background-color: var(--bfc-base-1);
  --bf-nav-side-width: 280px;
}

/* .scrollbar-container .ps__rail-y,
.scrollbar-container .ps__rail-y:hover {
  background-color: var(--bfc-base-c-2);
  width: 12px;
} */

.scrollbar-container .ps__thumb-y,
.scrollbar-container .ps__thumb-y:hover {
  background-color: var(--bfc-base-c-2);
  /* width: 8px; */
}

.bf-nav-mobile {
  padding-bottom: 0px;
}

.bf-modal-wrapper {
  z-index: 999;
}
