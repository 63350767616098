.dashboard-container {
  height: 100%;
  width: 100%;
  padding: 30px 140px;
}

/* MapSelect */
.map-select-container {
  margin: 30px 0px;
}
.map-select-container .map-select-header,
.map-select-container .map-select-public-map {
  margin: 4px 0;
}
.map-select-container .bf-button {
  align-self: flex-end;
  margin-top: 12px;
}

/* Drafts */
.drafts-container {
  margin-top: 30px;
}
.drafts-container .drafts-title {
  margin: var(--bfs4) 0;
}
.drafts-container .drafts-description {
  color: var(--bfc-base-c-2);
  margin: var(--bfs4) 0;
}
.drafts-container .search-sort-container {
  display: flex;
  position: relative;
  margin: 12px 0;
}
.drafts-container .search-sort-container .bf-input-container {
  min-width: 300px;
}
.drafts-container .search-sort-container .bf-select-container {
  position: absolute;
  right: 0;
  min-width: 165px;
}
.drafts-container .create-new-draft {
  position: absolute;
  top: calc(56px + 30px + 32px);
  right: 140px;
}
.drafts-container .create-new-draft svg {
  padding-top: 4px;
  width: 18px;
  height: 18px;
}
.drafts-container .autocol-container {
  height: calc(100vh - 510px);
  overflow: auto;
}

/* Draft */
.draft-container {
  display: flex;
  min-height: 130px;
  background: var(--bfc-base-3);
  border: 1px solid var(--bfc-base-dimmed);
  border-radius: 3px;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
}

.draft-container .draft-content-container {
  display: flex;
  padding: 10px;
  height: 100%;
}

.draft-container .draft-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  padding-right: 15px;
}

.draft-container .draft-title {
  display: flex;
  align-items: center;
  max-width: 90%;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0;
  outline: none;
  border: 1px solid transparent;
  padding: 4px;
  margin-left: -4px;
}
.draft-container .draft-title:hover {
  text-decoration: underline;
  cursor: pointer;
}
.draft-container .draft-title.focus-visible {
  border: 1px dashed var(--bfc-base-c-theme);
}

.draft-container .draft-created-edited {
  margin-bottom: 8px;
}
.draft-container .draft-created-edited p {
  margin: 0;
  font-size: 12px;
  font-style: italic;
  color: var(--bfc-base-c-2);
}

.draft-container .drafts-empty-seats {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.draft-container .drafts-empty-seats svg {
  color: var(--bfc-base-c-2);
  margin-right: 8px;
  font-size: 12px;
}

.draft-container .drafts-empty-seats p {
  margin: 0;
  font-size: 12px;
}

.draft-container .draft-dropdown-icon {
  cursor: pointer;
  border-radius: 50%;
  width: 25px !important;
  height: 25px;
  padding: 5px;
  outline: none;
  border: 1px dashed transparent;
}
.draft-container .draft-dropdown-icon:hover {
  background: var(--bfc-base-dimmed);
}
.draft-container .draft-dropdown-icon.focus-visible {
  border-color: var(--bfc-base-c-theme);
}
.draft-container .bf-badge {
  margin: 4px 0px;
  width: fit-content;
  width: -moz-fit-content;
}

.editing-badge svg {
  margin-right: 4px;
}

.draft-container .draft-footer {
  background: var(--bfc-base-2);
  border-top: 1px solid var(--bfc-base-dimmed);
  padding: 6px 10px;
}

.draft-container .draft-footer p {
  margin: 0;
  font-size: 12px;
  font-style: italic;
  color: var(--bfc-base-c-2);
}

/* Dropdown */
.bf-darkmode .draft-container .bf-dropdown {
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
.bf-lightmode .draft-container .bf-dropdown {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}
.draft-container .bf-dropdown {
  min-width: 140px;
}
.draft-container .bf-dropdown,
.draft-container .tippy-content {
  padding: 0;
}
.drop-down-content div {
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
}
.drop-down-content button {
  background: transparent;
  border: none;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  margin: 6px 0;
}
.drop-down-content .bf-title-link,
.drop-down-content .bf-title-link:hover {
  color: var(--bfc-base-c);
  padding: 4px;
}
.drop-down-content div:last-child {
  border-top: 1px solid var(--bfc-base-dimmed);
}
.drop-down-content .public-save,
.drop-down-content .public-save:hover {
  color: var(--bfc-base-disabled);
  cursor: default;
}
.drop-down-content .public-save:disabled {
  color: var(--bfc-base-disabled);
  cursor: default;
  text-decoration: none;
}

/* Modals */
.add-new-draft-modal {
  min-width: 480px;
}
.add-new-draft-modal .bf-modal-header svg {
  width: 25px;
  margin-right: 4px;
  margin-bottom: -1px;
}
.bf-modal-footer .buttons-container {
  display: flex;
  width: 250px;
  justify-content: space-between;
}

.select-public-map-modal .bf-select-container {
  min-width: 400px;
}

.unlock-draft-modal p {
  max-width: 450px;
  margin: 8px 0px;
}
