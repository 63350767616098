.seating-header-container {
  background: linear-gradient(
    180deg,
    var(--bfc-base-3) 18.75%,
    var(--bfc-base-1) 100%
  );
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.floor-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.floor-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--bfc-base-c-2);
  margin: 0;
}

.floor-title-container .bf-badge {
  margin-left: 12px;
}

/* filesettings menu*/

.seating-header-container .save-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 959px) {
  .view-mode .seating-header-container {
    padding: 8px 8px;
  }
  .view-mode .floor-title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .view-mode .floor-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--bfc-base-c-2);
    margin: 0;
  }
  .view-mode .floor-title-container .bf-badge {
    font-size: 12px;
    margin-left: 0;
  }

  .view-mode .seating-header-container .save-name-container p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1600px) {
  .view-mode .seating-header-container {
    display: flex;
    justify-content: space-between;
  }

  .view-mode .seating-header-container .save-name-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
  }
}

.seating-header-container .save-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  color: var(--bfc-base-c-1);
}

.seating-header-container .action-dropdown-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  max-width: 100%;
  background-color: transparent;
  border: none;
  padding: 8px;
  box-sizing: border-box;
}

.seating-header-container .action-dropdown-btn svg {
  margin-left: 8px;
}

.seating-header-container .action-dropdown-btn:hover,
.seating-header-container .action-dropdown-btn .save-title:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--bfc-base-c-1);
}

/*action dropdown*/

.bf-darkmode .seating-header-container .save-name-container .bf-dropdown {
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
.bf-lightmode .seating-header-container .save-name-container .bf-dropdown {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}

.seating-header-container .save-name-container .bf-dropdown {
  margin-top: -10px;
}
.seating-header-container .save-name-container .tippy-content,
.seating-header-container .save-name-container .bf-dropdown {
  padding: 0;
}

.seating-header-container .action-section:first-child {
  border-top: none;
}
.seating-header-container .action-section {
  border-top: 1px solid #636363;
  width: 100%;
  padding: 8px;
}

.seating-header-container .action.bf-title-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: var(--bfc-base-c-1);
  background-color: transparent;
  border: none;
  width: fit-content;
  width: -moz-fit-content;
  min-width: 100%;
  box-sizing: border-box;
}

.seating-header-container .action svg {
  margin-left: 16px;
  color: var(--bfc-base-c-2);
}

.seating-header-container .action p {
  line-height: 20px;
  color: var(--bfc-base-c-1);
  margin: 0;
}

.seating-header-container .action:hover p,
.seating-header-container .action:hover svg {
  color: var(--bfc-base-c-1);
  text-decoration: underline;
}

.seating-header-container .action span {
  font-weight: bold;
}

.seating-header-container .action.disabled p,
.seating-header-container .action.disabled svg,
.seating-header-container .action.disabled,
.seating-header-container .action.disabled:hover p,
.seating-header-container .action.disabled:hover svg,
.seating-header-container .action.disabled:hover {
  color: var(--bfc-base-disabled);
  cursor: default;
  text-decoration: none;
}
