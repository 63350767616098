:root {
  --employee-card-width: 300px;
}

.employee-card {
  position: absolute;
  width: 300px;
  background-color: var(--bfc-base-1);
  border-radius: 5px;
  overflow: hidden;
  cursor: auto;
  text-align: start;
  user-select: text;
  box-shadow: 0px 4px 10px 0px #00000070;
}

/* Department color */
.employee-card .employee-card-header {
  min-height: 100px;
  background-image: url("../../assets/info-card-BG.png");
  background-blend-mode: overlay;
  background-size: cover;
}
.employee-card .bf-card-logo {
  margin-top: -40px;
}
/* Employee picture */
.employee-card .bf-card-logo {
  height: 80px;
  width: 80px;
  background-color: var(--bfc-base-c-wcag);
  color: var(--bfc-base-1);
}
/* Employee name */
.employee-card .bf-card-title {
  color: var(--bfc-base-c-1);
  margin-bottom: 2px;
}
/* Employee department and jobtitle */
.employee-card .bf-card > p {
  margin: 4px 12px;
  font-size: 14px;
  color: var(--bfc-base-c-2);
}
.employee-card .bf-card-content {
  margin: 24px 0px;
}
/* Contact info */
.employee-card .contact-info span {
  color: var(--bfc-base-c-2);
  padding-right: 5px;
}
.employee-card .contact-info p {
  margin: 8px 0;
}
/* Comment */
.employee-card .comment-container p {
  margin: 6px 0;
  line-height: 18px;
}
.employee-card .bf-card-content .comment-container > p:first-child {
  color: var(--bfc-base-c-2);
  margin-top: 24px;
}
.employee-card .comment-container p svg {
  color: var(--bfc-theme-1);
  padding: 3px;
  margin-left: -3px;
  margin-right: 2px;
}
