.bf-modal-header {
  max-width: 600px;
}
.modal-btns-container button:last-child {
  margin-left: var(--bfs16);
}
.modal-text {
  margin: 0;
  max-width: 600px;
}
.comment-modal .comment-modal-textarea {
  min-width: 400px;
  min-height: 100px;
}
.comment-modal .bf-modal-header svg {
  width: 30px;
  padding-top: 4px;
}
